import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../maskbox';

class Internalpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      score:0,
      mynum:'',
      topuserlist:[],
      isloadingover:false
    };
  }
  componentDidMount() {
    this.getpointlist();
  }
  getpointlist = ()=>{
    fetch(
      "https://shl-info.cn/front/shortvideo/getusersalepointslist?userid=" +
      localStorage.getItem('userid'),{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        this.setState({
          isloadingover:true
        })
        if(res && res.code==2000){
          this.setState({
            score:res.data.current_user_points,
            topuserlist:res.data.top_users,
            mynum:res.rank,

          })
        }else{
          
          // this.props.history.push('/homeperson')
        }
      })

   
  }
  
  componentWillUnmount() { }
  render() {
    
    return (
      <div className="scorelayer2023 ">
      <div className="scorelayer2023box internalpointslayer center">
          <div className="scorelayer2023box_title">
          积分排行榜
              <div className="scorelayer2023box_title_close" onClick={()=>this.props.closepointstatelayer()} ></div>
          </div>
          
          <div className="internalpointslayerbox">
            <div className='internalpointslayerbox_title'>我的排名：ＮO.{this.state.mynum}

              <span className='internalpointslayerbox_detail' onClick={()=>this.props.changepointsdetaillayer()} >积分详情</span>
            </div>
            {this.state.isloadingover?this.state.topuserlist.length>0?'': <div className='internalpointsdetail_no'>暂无内容</div>: <div className='internalpointsdetail_no'>加载中</div>}
          {this.state.topuserlist.length>0?this.state.topuserlist.map((item,index)=>{
            return  <div  key={"pointsuserlist"+index}  className='myinternal_ranklist_li'>
            <div className='myinternal_ranklist_li_num'>{index>=0&& index<=2?<img src={require("../../static/images/ranking-"+(index+1)+".png")} />:
                         index+1
                        }</div>
            <div className={index==0?'myinternal_ranklist_li_headimg first':'myinternal_ranklist_li_headimg '}><img src={item.headimgurl?item.headimgurl:require("../../static/images/icon-portrait.png")} /></div>
            <div className='myinternal_ranklist_li_nickname'>{item.name}</div>
            <div className='myinternal_ranklist_li_point'>+{item.total_points}</div>
          </div>
          }):null}
           
            
{/*             
            <div className='myinternal_ranklist_li'>
              <div className='myinternal_ranklist_li_num'>2</div>
              <div className='myinternal_ranklist_li_headimg'><img src={require("../../static/images/groupcodebg.png")} /></div>
              <div className='myinternal_ranklist_li_nickname'>当前积分：当前积分：</div>
              <div className='myinternal_ranklist_li_point'>+10000</div>
            </div>
            
            <div className='myinternal_ranklist_li'>
              <div className='myinternal_ranklist_li_num'>2</div>
              <div className='myinternal_ranklist_li_headimg'><img src={require("../../static/images/groupcodebg.png")} /></div>
              <div className='myinternal_ranklist_li_nickname'>当前积分：当前积分：</div>
              <div className='myinternal_ranklist_li_point'>+10000</div>
            </div>
            
            <div className='myinternal_ranklist_li'>
              <div className='myinternal_ranklist_li_num'>2</div>
              <div className='myinternal_ranklist_li_headimg'><img src={require("../../static/images/groupcodebg.png")} /></div>
              <div className='myinternal_ranklist_li_nickname'>当前积分：当前积分：</div>
              <div className='myinternal_ranklist_li_point'>+10000</div>
            </div>
            
            <div className='myinternal_ranklist_li'>
              <div className='myinternal_ranklist_li_num'>2</div>
              <div className='myinternal_ranklist_li_headimg'><img src={require("../../static/images/groupcodebg.png")} /></div>
              <div className='myinternal_ranklist_li_nickname'>当前积分：当前积分：</div>
              <div className='myinternal_ranklist_li_point'>+10000</div>
            </div> */}


          </div>
          
          
      </div>
  </div >
    );
  }
}

export default Internalpoints;
