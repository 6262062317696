import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import './index.css'
let mousestate = false;


function PersonImg(props) {
    console.log(props)
    let widthleng = props.signlevelscore / 200 * 136;
    let leveltext = null;
    if (props.cslevel >= 0 && localStorage.getItem('local') == 'BP Sale') {
        if (props.cslevel == 1) {
            leveltext = <span>(超声级别：一级)</span>;
        } else if (props.cslevel == 2) {
            leveltext = <span>(超声级别：二级)</span>;
        } else if (props.cslevel == 3) {
            leveltext = <span>(超声级别：三级)</span>;
        } else if (props.cslevel == 4) {
            leveltext = <span>(超声级别：四级)</span>;
        }
    }
    const changeopenstate = () =>{
        props.changeopenstate();
        // if(localStorage.getItem('special')==1){
            
        // console.log(3333)
        //     localStorage.setItem('special',11)
        //     // this.setState({
        //     //   specialstate:11
        //     // })
        //   }else{
              
        // console.log(2222)
        //     localStorage.setItem('special',1)
        //     // this.setState({
        //     //   specialstate:1
        //     // })
        //   }
    }
    
   

    leveltext = null;
    console.log('等级', leveltext)
    console.log(mousestate)
    return (
        <section className="common-person">
            <div className="wrap">
                <div className="common-personbox">
                    <div className={props.isperson == 1  ? "common-personbox-rightactive " : "common-personbox-rightactive none"}>
                    {/* <div className={props.isperson == 1 && props.thisnum != 3 ? "common-personbox-rightactive " : "common-personbox-rightactive none"}> */}
                        <div className={props.signstate ? "personbox-rightactive active" : ' personbox-rightactive'} onClick={() => props.todayclick()}>签到</div>
                        <div className="personbox-rightactive-text">已连续签到{props.signnums}天</div>

                           {/* 新加申请CS权限按扭 */}
                        {/* {(localStorage.getItem('iswaitype') == 0 || localStorage.getItem('iswaitype') == 4)?<div className={thisusertype ? "manage-reg-sign " : "manage-reg-sign "} >
                            {this.state.regday>0 && this.state.sysstate !=1?<div className='reghavdays'>致道微课权限剩余{this.state.regday}天</div>: <div className="manage-reg-sign-btn " onClick={() => this.regclick()}>申请致道微课权限</div>}
                        </div>:null} */}
                    </div>

                    {(localStorage.getItem('iswaitype') ==1 || localStorage.getItem('iswaitype') ==2 || localStorage.getItem('iswaitype') ==3 || localStorage.getItem('iswaitype') ==5 ) 
              &&  (localStorage.getItem('isopen') ==1 && props.isonlyshow
                // || localStorage.getItem('userid') ==50869
                // || localStorage.getItem('userid') ==50870
                // || localStorage.getItem('userid') ==50871
                // || localStorage.getItem('userid') ==50872
                // || localStorage.getItem('userid') ==50873
                // || localStorage.getItem('userid') ==50874
                // || localStorage.getItem('userid') ==50875   
              )?<div className='isspecial_btn'>
                        <div className='isspecial_btn_title'>课程推荐</div>
                        <div className={localStorage.getItem('special')!=1?"isspecial_btn_icon active":"isspecial_btn_icon "} onClick={()=>changeopenstate()}>
                        <span></span>
                        </div>
                        <div className='isspecial_tips' onClick={()=>props.changeopenisspecialtips()}>
                            {props.openisspecialtips?<div className='isspecial_tips_text'>开启后会推荐对应科室的精品课程</div>:null}
                        </div>
                    </div>:null }
                    <span className="person-img">
                        <img src={props.userinfo.pic} alt="" />
                    </span>
                    <p className={leveltext != null ? "person-name active" : "person-name"}>{props.userinfo.info.name} {leveltext}</p>
                    {props.isperson == 1?<div className="person-name-score">当前积分：<span>{props.score}</span></div>:null}    
                    <div className={props.isperson == 1  ? "person-name-lives  none" : "none"}><span>
                    {/* <div className={props.isperson == 1 && props.thisnum != 3 ? "person-name-lives " : "none"}><span> */}
                        {/* <i >Lv</i>. */}
                        {props.signlevel == 1
                            ? '南岳新秀'
                            : props.signlevel == 2
                                ? "玄嵩青栎"
                                : props.signlevel == 3
                                    ? "岱宗劲柏"
                                    : props.signlevel == 4
                                        ? "幽恒云松"
                                        : props.signlevel == 5
                                            ? "太华寒杉"
                                            : props.signlevel == 6
                                                ? "珠穆朗玛" : null}

                    </span> ( {props.signlevelscore} / 200 )
                    <span className='ts' onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} >
                            <div className={props.mousestate ? "iconslist" : "iconslist none"}>
                                <div >Level 1：南岳新秀</div>
                                <div >Level 2：玄嵩青栎</div>
                                <div >Level 3：岱宗劲柏</div>
                                <div >Level 4：幽恒云松</div>
                                <div >Level 5：太华寒杉</div>
                                <div >Level 6：珠穆朗玛</div>
                            </div>
                        </span>
                    </div>
                    <div className={props.isperson == 1  ? "person-name-line none " : 'none'}>
                    {/* <div className={props.isperson == 1 && props.thisnum != 3 ? "person-name-line " : 'none'}> */}
                        <span>
                            <i style={{ width: widthleng + 'px' }}></i>
                        </span>
                    </div>
               {/* {props.backusernumstate?<div className="backusernum">成功邀约{props.scoreusernum}位新注册用户<span onClick={()=>props.showlayer()}>明细</span></div>:null} */}
               {props.isinteranl?<div className="backusernum"><p>成功邀约{props.insidesourcenum}位新注册用户<span onClick={()=>props.showlayer()}>明细</span></p><p onClick={()=>props.showranklayer()}>当前内部排名：<span className='ranknum' >{props.ranknum}</span>名</p></div>:props.backusernumstate?<div className="backusernum">成功邀约{props.scoreusernum}位新注册用户<span onClick={()=>props.showlayer()}>明细</span></div>:null}
               {/* {props.backusernumstate?<div className="backusernum">成功邀约{props.backusernum}位医生加入</div>:null} */}
                    {
                        props.type === 'yes' ? (
                            <div className="person-account cursor">
                                <Link to="/subaccount">
                                    子账号管理
                                </Link>
                            </div>
                        ) : null
                    }
                </div>
                {
                    props.type === 'addmore' ? (
                        <span className="cursor change-information">
                            <Link to="/addaccount?type=1">
                                <img src={require('../../static/images/pen.png')} alt="" />
                                修改资料
                            </Link>
                        </span>
                    ) : null
                }
            </div>
        </section>
    )
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
    return {
        userinfo: state.userinfo
    }
}

// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonImg)
