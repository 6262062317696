import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import LayoutClass from '../../components/layoutclass'
import CLassLabel from '../../components/classlabel'
import PersonalList from '../../components/personallist'
import Internalpoints from '../../components/internalpoints'
import Internalpointsdetail from '../../components/internalpointsdetail'




import Scorelayer from '../../components/scorelayer2023';


import './index.css'

class HomePerson extends Component {
  constructor(props) {
    super(props);
    //console.log(props.info)

    this.todayclick = this.todayclick.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)


    

    //todayclick
    this.state = {
      
      usersalepointsstate:false,
      showusersalepointlist:false,
      showusersalepointdetail:false,

      thisnum: 0,
      signstate: false,
      todaysign: true,

      isinteranl:false,
      ranknum:0,
      insidesourcenum:0,
      showrank:false,
      ranklist:[],


      cslevel: 0,
      openisspecialtips:false,
      signnums: 0,

      mousestate:false,

      signscore: 0,
      signlevel: 0,
      signallscore: 0,
      signlevelscore: 0,

      iswaitype:false,

      backusernum:0,

      specialstate:0,

      eglistlayerstate:false,


      showlayer:false,
      scorelist:[],
      scoreusernum:0,
      score:0,

      scorelsyerstate:false,
      scorelsyerlist:{
        score:"",
        content:"",
      },
      personaladdlist: [
        {
          id: 201,
          icon: require('../../static/images/studyweek.png'),
          title: '学习周',
          link: '/studyweek'
        },
      ],

      personallist: [
        {
          id: 0,
          icon: require('../../static/images/per-icon1.png'),
          title: '观看历史',
          link: '/watchhistory'
        },
        {
          id: 1,
          icon: require('../../static/images/per-icon2.png'),
          title: '学习记录',
          link: '/learnrecord'
        },
        {
          id: 2,
          icon: require('../../static/images/per-icon3.png'),
          title: '我的证书',
          link: '/certificate'
        },
        {
          id: 3,
          icon: require('../../static/images/per-icon4.png'),
          title: '下载记录',
          link: '/downrecord'
        },
        {
          id: 21,
          icon: require('../../static/images/per-icon21.png'),
          title: '积分商城',
          link: '/invitelist'
        },
        {
          id: 4,
          icon: require('../../static/images/per-icon5.png'),
          title: '学习计划',
          link: '/learnplan'
        },
        {
          id: 7,
          title: '调研',
          icon: require("../../static/images/per-icon12.png"),
          link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
        }
      ]
    }
  }
  static defaultProps = {
    personallist: [
      {
        id: 0,
        icon: require('../../static/images/per-icon1.png'),
        title: '观看历史',
        link: '/watchhistory'
      },
      {
        id: 1,
        icon: require('../../static/images/per-icon2.png'),
        title: '学习记录',
        link: '/learnrecord'
      },
      {
        id: 2,
        icon: require('../../static/images/per-icon3.png'),
        title: '我的证书',
        link: '/certificate'
      },
      {
        id: 3,
        icon: require('../../static/images/per-icon4.png'),
        title: '下载记录',
        link: '/downrecord'
      },
      {
        id: 21,
        icon: require('../../static/images/per-icon21.png'),
        title: '积分商城',
        link: '/invitelist'
      },
      {
        id: 4,
        icon: require('../../static/images/per-icon5.png'),
        title: '学习计划',
        link: '/learnplan'
      },
      {
        id: 7,
        name: '调研',
        src: require("../../static/images/per-icon12.png"),
        link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
      }
      // {
      //   id: 5,
      //   icon: require('../../static/images/per-icon6.png'),
      //   title: '我的收藏',
      //   link: '/collect'
      // },
      // {
      //   id: 6,
      //   icon: require('../../static/images/per-icon7.png'),
      //   title: '定制海报',
      //   link: ''
      // }
    ]
  }

  componentDidMount() {
    console.log(this.props)
    this.props.change_person_classlist()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    let iswaitype =  (localStorage.getItem('iswaitype'));
  
    let that = this
    this.outpersonallistarray = [
      {
        id: 0,
        icon: require('../../static/images/per-icon1.png'),
        title: '观看历史',
        link: '/watchhistory'
      },
      {
        id: 1,
        icon: require('../../static/images/per-icon2.png'),
        title: '学习记录',
        link: '/learnrecord'
      },
      {
        id: 2,
        icon: require('../../static/images/per-icon3.png'),
        title: '我的证书',
        link: '/certificate'
      },
      // {
      //   id: 24,
      //   title: '兑换卷码',
      //   icon: require("../../static/images/per-icon8.png"),
      //   link: '/checkcode'
      // },
      {
        id: 21,
        icon: require('../../static/images/per-icon21.png'),
        title: '积分商城',
        link: '/invitelist'
      },
      {
        id: 4,
        icon: require('../../static/images/per-icon5.png'),
        title: '学习计划',
        link: '/learnplan'
      },
      {
        id: 7,
        title: '调研',
        icon: require("../../static/images/per-icon12.png"),
        link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
      },
      // {
      //   id: 11,
      //   title: '兑换记录',
      //   icon: require("../../static/images/per-icon12.png"),
      //   link: '/exchangedata'
      // },
      {
        id: 31,
        icon: require('../../static/images/per-icon12.png'),
        title: '我的报名',
        link: '/myregistration'
      },
      // {
      //   id: 8,
      //   title: '互动专区',
      //   icon: require("../../static/images/icon-8.png"),
      //   link: '/userPost'
      // }
    ];
    this.personallistarray=[
      {
        id: 0,
        icon: require('../../static/images/per-icon1.png'),
        title: '观看历史',
        link: '/watchhistory'
      },
      {
        id: 1,
        icon: require('../../static/images/per-icon2.png'),
        title: '学习记录',
        link: '/learnrecord'
      },
      {
        id: 2,
        icon: require('../../static/images/per-icon3.png'),
        title: '我的证书',
        link: '/certificate'
      },
      // {
      //   id: 24,
      //   title: '兑换卷码',
      //   icon: require("../../static/images/per-icon8.png"),
      //   link: '/checkcode'
      // },
      {
        id: 21,
        icon: require('../../static/images/per-icon21.png'),
        title: '积分商城',
        link: '/invitelist'
      },
      {
        id: 4,
        icon: require('../../static/images/per-icon5.png'),
        title: '学习计划',
        link: '/learnplan'
      },
      {
        id: 7,
        title: '调研',
        icon: require("../../static/images/per-icon12.png"),
        link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
      },
      // {
      //   id: 11,
      //   title: '兑换记录',
      //   icon: require("../../static/images/per-icon12.png"),
      //   link: '/exchangedata'
      // },
      {
        id: 31,
        icon: require('../../static/images/per-icon12.png'),
        title: '我的报名',
        link: '/myregistration'
      },
      // {
      //   id: 8,
      //   title: '互动专区',
      //   icon: require("../../static/images/icon-8.png"),
      //   link: '/userPost'
      // }
    ]
    if (userid && token) {
      console.log(iswaitype)
      console.log("iswaitype")
      if(iswaitype && localStorage.getItem('changetype') == 1 && (iswaitype ==0 || iswaitype ==4)){
        console.log("iswaitype")
        that.setState({
          iswaitype:true,
        })
      }
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          console.log(22222222222)
          if(res.status==2){
            localStorage.setItem('userid','');
            localStorage.setItem('token','');
            return
          }
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)
            
            localStorage.setItem('iswaitype', res.list.type)
            
            that.getpointlist();
            // 临时注释掉内部邀请功能计算
            // if((res.list.type ==0 ||  res.list.type ==4) && res.list.ruletype==1){
            //   that.setState({
            //     isinteranl:true,
            //   })

            //   fetch(
            //     "https://shl-info.cn/front/Scoreshop/getSourceRankingList?userid=" + userid ,{
            //       headers:{
            //         'token':token
            //       }
            //     }
            //   )
            //     .then(data => {
            //       return data.json();
            //     })
            //     .then(resrank => {
            //       that.setState({
            //         ranklist: resrank.data,
            //         ranknum:resrank.rank
            //       })
            //     })
            // } 
            if (res.list.type == 4) {
              that.setState({
                thisnum: 2,
              })
              that.personallistarray.push(
                [{
                  id: 3,
                  icon: require('../../static/images/per-icon4.png'),
                  title: '下载记录',
                  link: '/downrecord'
                },{
                  id: 5,
                  title: '学员计划',
                  icon: require("../../static/images/per-icon11.png"),
                  link: '/learningplan'
                }])
                // this.personallist.push(
                //   {
                //     id: 5,
                //     title: '学员计划',
                //     icon: require("../../static/images/per-icon11.png"),
                //     link: '/learningplan'
                //   })
              that.setState({
                personallist: [
                  {
                    id: 0,
                    icon: require('../../static/images/per-icon1.png'),
                    title: '观看历史',
                    link: '/watchhistory'
                  },
                  {
                    id: 1,
                    icon: require('../../static/images/per-icon2.png'),
                    title: '学习记录',
                    link: '/learnrecord'
                  },
                  {
                    id: 2,
                    icon: require('../../static/images/per-icon3.png'),
                    title: '我的证书',
                    link: '/certificate'
                  },
                  {
                    id: 3,
                    icon: require('../../static/images/per-icon4.png'),
                    title: '下载记录',
                    link: '/downrecord'
                  },
                  {
                    id: 21,
                    icon: require('../../static/images/per-icon21.png'),
                    title: '积分商城',
                    link: '/invitelist'
                  },
                  {
                    id: 4,
                    icon: require('../../static/images/per-icon5.png'),
                    title: '学习计划',
                    link: '/learnplan'
                  },
                  {
                    id: 7,
                    title: '调研',
                    icon: require("../../static/images/per-icon12.png"),
                    link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
                  },
                  {
                    id: 5,
                    title: '学员计划',
                    icon: require("../../static/images/per-icon11.png"),
                    link: '/learningplan'
                  }
                ]
              })
            } else if (res.list.type == 1 || res.list.type == 2 || res.list.type == 3 || res.list.type == 5) {

              that.setState({
                thisnum: 3,
                iswaitype:true,
              })
             
              console.log("that.outpersonallistarray")
              that.setState({
                personallist: [
                  {
                    id: 0,
                    icon: require('../../static/images/per-icon1.png'),
                    title: '观看历史',
                    link: '/watchhistory'
                  },
                  {
                    id: 1,
                    icon: require('../../static/images/per-icon2.png'),
                    title: '学习记录',
                    link: '/learnrecord'
                  },
                  {
                    id: 2,
                    icon: require('../../static/images/per-icon3.png'),
                    title: '我的证书',
                    link: '/certificate'
                  },
                  // {
                  //   id: 24,
                  //   title: '兑换卷码',
                  //   icon: require("../../static/images/per-icon8.png"),
                  //   link: '/checkcode'
                  // },
                  {
                    id: 21,
                    icon: require('../../static/images/per-icon21.png'),
                    title: '积分商城',
                    link: '/invitelist'
                  },
                  {
                    id: 4,
                    icon: require('../../static/images/per-icon5.png'),
                    title: '学习计划',
                    link: '/learnplan'
                  },
                  {
                    id: 7,
                    title: '调研',
                    icon: require("../../static/images/per-icon12.png"),
                    link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
                  },
                  // {
                  //   id: 11,
                  //   title: '兑换记录',
                  //   icon: require("../../static/images/per-icon12.png"),
                  //   link: '/exchangedata'
                  // },
                  {
                    id: 31,
                    icon: require('../../static/images/per-icon12.png'),
                    title: '我的报名',
                    link: '/myregistration'
                  },
                  // {
                  //   id: 8,
                  //   title: '互动专区',
                  //   icon: require("../../static/images/icon-8.png"),
                  //   link: '/userPost'
                  // }
                ]
              })


              if(window.location.href.indexOf('cscoursestate=1')>=0){
                console.log(that.personallistarray)
                that.personallistarray.push(
                  {
                    id: 11,
                    title: '兑换记录',
                    icon: require("../../static/images/per-icon12.png"),
                    link: '/exchangedata'
                  })
                  console.log(that.personallistarray)
                  console.log("this.personallistarray")
                that.setState({
                  personallist: [
                    {
                      id: 0,
                      icon: require('../../static/images/per-icon1.png'),
                      title: '观看历史',
                      link: '/watchhistory'
                    },
                    {
                      id: 1,
                      icon: require('../../static/images/per-icon2.png'),
                      title: '学习记录',
                      link: '/learnrecord'
                    },
                    {
                      id: 2,
                      icon: require('../../static/images/per-icon3.png'),
                      title: '我的证书',
                      link: '/certificate'
                    },
                    {
                      id: 21,
                      icon: require('../../static/images/per-icon21.png'),
                      title: '积分商城',
                      link: '/invitelist'
                    },
                    {
                      id: 4,
                      icon: require('../../static/images/per-icon5.png'),
                      title: '学习计划',
                      link: '/learnplan'
                    },
                    {
                      id: 7,
                      title: '调研',
                      icon: require("../../static/images/per-icon12.png"),
                      link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
                    },
                    {
                      id: 11,
                      title: '兑换记录',
                      icon: require("../../static/images/per-icon12.png"),
                      link: '/exchangedata'
                    },
                  ]
                })
              }


            } else {
              that.setState({
                thisnum: 1,
              })
            }
          }
        }
      });
    }



    fetch(
      "https://shl-info.cn/front/groupchat/getUserGroupRoleById?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
          console.log(res)
          if(res.data){
            that.personallistarray.push({
              id: 102,
              title: '群组功能',
              icon: require("../../static/images/icongroup.png"),
              link: '/groupManipulat'
            })
            
            that.outpersonallistarray.push({
              id: 102,
              title: '群组功能',
              icon: require("../../static/images/icongroup.png"),
              link: '/groupManipulat'
            })
          }
      })
    


    



    fetch(
      "https://shl-info.cn/front/score/getscorelevel?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        //res.data.current_level_score = 0;
        that.setState({
          signlevel: res.data.level,
          signallscore: res.data.total_score,
          signlevelscore: res.data.current_level_score,
          cslevel: res.data.chaosheng_level,
        })

      })

      fetch(
        "https://shl-info.cn/front/bbs/getBbsShareUser?userid=" + userid ,{
          headers:{
            'token':token
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          console.log(222222222222222)
          //res.data.current_level_score = 0;
          that.setState({
            backusernum:res,
          })
  
        })




    fetch(
      "https://shl-info.cn/front/score/getsignscore?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log('当前积分条数', res)
        if (res.has_sign <= 0) {
          that.setState({
            todaysign: false
          })
        }

        that.setState({
          signnums: res.sign_days
        })

        // console.log(res)
      })



    console.log(this.props)
    fetch(
      "https://shl-info.cn/front/frontc/getuserworks?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        if (res.status != -1) {

          that.personallistarray.push({
            id: 5,
            title: '学员计划',
            icon: require("../../static/images/per-icon11.png"),
            link: '/learningplan'
          })
          this.setState({
            personallist: [
              {
                id: 0,
                icon: require('../../static/images/per-icon1.png'),
                title: '观看历史',
                link: '/watchhistory'
              },
              {
                id: 1,
                icon: require('../../static/images/per-icon2.png'),
                title: '学习记录',
                link: '/learnrecord'
              },
              {
                id: 2,
                icon: require('../../static/images/per-icon3.png'),
                title: '我的证书',
                link: '/certificate'
              },
              {
                id: 3,
                icon: require('../../static/images/per-icon4.png'),
                title: '下载记录',
                link: '/downrecord'
              },
              {
                id: 21,
                icon: require('../../static/images/per-icon21.png'),
                title: '积分商城',
                link: '/invitelist'
              },
              {
                id: 4,
                icon: require('../../static/images/per-icon5.png'),
                title: '学习计划',
                link: '/learnplan'
              },
              {
                id: 7,
                title: '调研',
                icon: require("../../static/images/per-icon12.png"),
                link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
              },
              {
                id: 5,
                title: '学员计划',
                icon: require("../../static/images/per-icon11.png"),
                link: '/learningplan'
              },
              {
                id: 31,
                icon: require('../../static/images/per-icon12.png'),
                title: '我的报名',
                link: '/myregistration'
              },
            ]
          })
        }
      })



    fetch(
      "https://shl-info.cn/front/frontc/studentlist?userid=" + userid ,{
        headers:{
          'token':token
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res);
        if (res && res.length > 0) {

          
          that.personallistarray.push(
            {
              id: 8,
              title: '作业批改',
              icon: require("../../static/images/per-icon13.png"),
              link: '/learningplanadminuserlist'
            })


          this.setState({
            personallist: [
              {
                id: 0,
                icon: require('../../static/images/per-icon1.png'),
                title: '观看历史',
                link: '/watchhistory'
              },
              {
                id: 1,
                icon: require('../../static/images/per-icon2.png'),
                title: '学习记录',
                link: '/learnrecord'
              },
              {
                id: 2,
                icon: require('../../static/images/per-icon3.png'),
                title: '我的证书',
                link: '/certificate'
              },
              {
                id: 3,
                icon: require('../../static/images/per-icon4.png'),
                title: '下载记录',
                link: '/downrecord'
              },
              {
                id: 21,
                icon: require('../../static/images/per-icon21.png'),
                title: '积分商城',
                link: '/invitelist'
              },
              {
                id: 4,
                icon: require('../../static/images/per-icon5.png'),
                title: '学习计划',
                link: '/learnplan'
              },
              {
                id: 7,
                title: '调研',
                icon: require("../../static/images/per-icon12.png"),
                link: 'https://fe.ma.scrmtech.com/quest/answer_sheet?main_id=13205&wx_id=1564&survey_id=530'
              },
              {
                id: 5,
                title: '学员计划',
                icon: require("../../static/images/per-icon11.png"),
                link: '/learningplan'
              },
              {
                id: 8,
                title: '作业批改',
                icon: require("../../static/images/per-icon13.png"),
                link: '/learningplanadminuserlist'
              },
              {
                id: 31,
                icon: require('../../static/images/per-icon12.png'),
                title: '我的报名',
                link: '/myregistration'
              },



            ]
          })
        }
      });




      fetch(
        "https://shl-info.cn/front/Scoreshop/getScoreDetail?userid=" + localStorage.getItem('userid') ,{
          headers:{
            'token':localStorage.getItem('token')
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        this.setState({
         scoreusernum:res.data.sourcenum,
         insidesourcenum:res.data.insidesourcenum
         //  scoreusernum:1,
        })
      })
      fetch(
        "https://shl-info.cn/front/Scoreshop/getSourceUserInfo?userid=" + localStorage.getItem('userid') ,{
          headers:{
            'token':localStorage.getItem('token')
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        this.setState({
          score:res.data.userscore,
          scorelist:res.data.sourceuser
          //scoreusernum:1,
        })
      })


    setTimeout(()=>{
      that.personallistarray.push({
        id: 207,
        title: '网上商城',
        icon: require("../../static/images/shopmall/per-icon27.png"),
        link: '/shopmallindex'
      })
      
      that.outpersonallistarray.push({
        id: 207,
        title: '网上商城',
        icon: require("../../static/images/shopmall/per-icon27.png"),
        link: '/shopmallindex'
      })
    },100)

  }
  componentWillUnmount() {

  }
  todayclick() {
    if (this.state.todaysign) {
      if (this.state.signstate) {
        this.setState({
          signstate: !this.state.signstate,
        })
      }
      return false;
    }
    // this.setState({
    //   scorelsyerstate:true
    // })
    this.showscorelayer();


    //注释添加积分ESHOP
    //签到加积分

    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: 5,
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {

        //console.log(error);
      })
      .then(res => {
        console.log(res)
        let newnum = this.state.signnums + 1;
        if(res.code==2000){
          this.setState({
            todaysign: true,
            scorelsyerlist:res.data,
            // signscore: res.score,
            signnums: newnum,
          })
        }
        
        //console.log(res)
      });


    //加判断已签到不可以抛数据
    // $.ajax({
    //   type: "POST",
    //   url:
    //     "https://shl-info.cn/front/score/addlog?userid=" +
    //     localStorage.getItem('userid') +
    //     "&usertype=" +
    //     localStorage.getItem('usertype'),
    //   data: JSON.stringify({
    //     scoreid: 1,
    //     userid: localStorage.getItem('userid'),
    //     usertype: localStorage.getItem('usertype')
    //   }),
    //   dataType: "json",
      
    //   headers:{'token':localStorage.getItem('token')},
    //   cache: false
    // })
    //   .catch(error => {

    //     console.log(error);
    //   })
    //   .then(res => {
    //     console.log(res)
    //     var newdata = this.state.signnums + 1;
    //     this.setState({
    //       todaysign: true,
    //       signscore: res.score,
    //       signnums: newdata
    //     })

    //   });
    console.log(111)
  }

  getpointlist = ()=>{
    fetch(
      "https://shl-info.cn/front/shortvideo/getusersalepointslist?userid=" +
      localStorage.getItem('userid'),{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res && res.code==2000){
        this.setState({
          usersalepointsstate:true,
          score:res.data.current_user_points,
        })
      }
    })
  }

  changeopenstate =()=>{
    console.log(22222)
    this.setState({
      eglistlayerstate:true,
    })
  }

  changeopenstateok = ()=>{
    if(localStorage.getItem('special')==1){
      localStorage.setItem('special',11)

      fetch(
        "https://shl-info.cn/front/userrecommend/setUserRecommendInfo?userid=" + localStorage.getItem('userid') +"&isspecial=11" ,{
          headers:{
            'token':localStorage.getItem('token')
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
      })
      this.setState({
        specialstate:11,
        eglistlayerstate:false,
      })
    }else{
      localStorage.setItem('special',1)
      fetch(
        "https://shl-info.cn/front/userrecommend/setUserRecommendInfo?userid=" + localStorage.getItem('userid') +"&isspecial=2" ,{
          headers:{
            'token':localStorage.getItem('token')
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
      })
      this.setState({
        specialstate:1,
        eglistlayerstate:false,
      })
    }
  }
  changeopenstatelayerclose =() =>{
    this.setState({
      eglistlayerstate:false,
    })
  }







  onMouseEnter(){
    this.setState({
      mousestate:true,
    })
    console.log('onMouseEnter')
  }
  onMouseLeave(){
    this.setState({
      mousestate:false,
    })
    console.log('onMouseLeave')
  }
  changetype = () =>{
    // if(localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4){
    //   if(!this.state.iswaitype){
    //     localStorage.setItem('changetype', 1)
    //   }else{
    //     localStorage.setItem('changetype', '')
    //   }
    // }
    this.setState({
      iswaitype:!this.state.iswaitype,
    })
  }
  changepointsdetaillayer = ()=>{
    this.setState({
      showusersalepointlist:false,
      showusersalepointdetail:true,
    })
  }
  
  closepointstatedetaillayer = ()=>{
    this.setState({
      showusersalepointlist:true,
      showusersalepointdetail:false,
    })
  }

  closepointstatelayer = ()=>{
    this.setState({
      showusersalepointlist:false,
      showusersalepointdetail:false,
    })
  }

  
  changeopenisspecialtips = () => {
    this.setState({
      openisspecialtips:!this.state.openisspecialtips,
    })
  }
  showlayer = ()=>{
    console.log(1111)
    if(this.state.usersalepointsstate){
      this.setState({
        showusersalepointlist:true,
      }) 
    }else{
      this.setState({
        showlayer:true,
      })
    }
  }

  showranklayer = () =>{
    this.setState({
      showrank:true,
    })
  }
  
  closescorelayer = () =>{
    this.setState({
      scorelsyerstate:false
    })
  }

  showscorelayer = () =>{
    this.setState({
      scorelsyerstate:true
    })
  }

  render() {
    // 列表
    console.log(this.props.history)
    console.log(this.state.thisnum)
    console.log(this.personallistarray)
    console.log(this.outpersonallistarray)

   
    let hreflist = ''
    
    let hreflistnew = '';
    // $('.person-listbox').css("opacity",0);
    // $('.person-listbox').find('li').css('width',"10%");
    if(this.personallistarray && this.outpersonallistarray){

      if(localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4){

        let personallistarraystate=false;
        for(var i = 0;i<this.personallistarray.length;i++){
          if(this.personallistarray[i]['id']==101){
            personallistarraystate=true;
            
          }
        }
        let outpersonallistarraystate=false;
        for(var i = 0;i<this.outpersonallistarray.length;i++){
          if(this.outpersonallistarray[i]['id']==101){
            outpersonallistarraystate=true;
          }
        }
        if(!outpersonallistarraystate){
          // this.outpersonallistarray.push({
          //     id: 101,
          //     icon: require('../../static/images/per-icon4.png'),
          //     title: '切换身份',
          //     link: ''
          // })
        }
        
      }
      


      if(this.state.iswaitype && this.outpersonallistarray){
        hreflist = this.outpersonallistarray.map((item) => {

          
      if((localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4) && item.id==21){
        return null;
      }else{
        return (
          <PersonalList changetype={this.changetype}  key={item.id} width={localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4?this.outpersonallistarray.length-1:this.outpersonallistarray.length} _item={item} history={this.props.history}></PersonalList>
        )
      }
          
        })
      }else{
        hreflist = this.personallistarray.map((item) => {
          if((localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4) && item.id==21){
            return null;
          }else{
          return (
            <PersonalList changetype={this.changetype} key={item.id} width={localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4?this.personallistarray.length-1:this.personallistarray.length} _item={item} history={this.props.history}></PersonalList>
          )
          }
        })
      }

      if(localStorage.getItem('usertype') == 1 && localStorage.getItem('email')){
        console.log()
        hreflistnew = this.state.personaladdlist.map((item) => {
          return (
            <PersonalList changetype={this.changetype} key={item.id} width={localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4?this.personallistarray.length-1:this.personallistarray.length}  _item={item} history={this.props.history}></PersonalList>
          )
        })
        setTimeout(function(){
          let widbi = 100/$('.person-listbox').find('li').length;
          $('.person-listbox').find('li').css('width',widbi+"%");
        },200)
      }
    }
   
   

    if(localStorage.getItem('iswaitype') ==0 ||localStorage.getItem('iswaitype') ==4){
      console.log(1233211234)
      
      // this.personallistarray.push({
      //     id: 101,
      //     icon: require('../../static/images/per-icon4.png'),
      //     title: '切换身份',
      //     link: ''
      // })
      
      // this.outpersonallistarray.push({
      //   id: 101,
      //   icon: require('../../static/images/per-icon4.png'),
      //   title: '切换身份',
      //   link: ''
      // })
    }
    // 课程中心
    let classlist = null;
    if (this.props.classlist) {
      classlist = this.props.classlist.map((item) => {
        return (
          <CLassLabel
            key={'label' + item.id}
            _item={item}></CLassLabel>
        )
      })
    }

    let eglistlayer = null
    if(this.state.eglistlayerstate){
      eglistlayer = <div className='eglistlayer'>
        <div className="eglistlayerbox">
          <h2>提示
          {/* <span class="elast-close"></span> */}
          </h2>
          <div className='eglistlayerbox_text'>
          <p>{localStorage.getItem('special')==1?"您确定要要关闭课程推荐功能吗？":"您确定要要开启课程推荐功能吗？"}<br></br>{localStorage.getItem('special')==1?"关闭之后不会为您推荐定制课程":"开启之后会为您推荐定制课程"}</p>
            <div className="eglistlayerbox_btnlist">
              <div className='eglistlayerbtnleft' onClick={()=>this.changeopenstateok()}>
                确定
              </div>
              <div className='eglistlayerbtnright'  onClick={()=>this.changeopenstatelayerclose()}>
                取消
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    let ranklayer = null 
    if(this.state.showrank){
      ranklayer = <div className="scorelayer">
        <div className={this.state.ranklist && this.state.ranklist.length>0?"scorelayerbox":"scorelayerbox isnorank"}>
          <div className="scorelayerbox_title">内部员工裂变排名<div className="scorelayerbox_close" onClick={()=>this.setState({
            showrank:false,
          })}></div></div>
          {this.state.ranklist && this.state.ranklist.length>0?<><div className="scorelayerbox_list moreheight">
               <div className="iviteusersbox_list_linew bold">
                <span className="iviteusersbox_list_li_name">姓名</span>
                <span className="iviteusersbox_list_li_tel">邀请人数</span>
                <span className="iviteusersbox_list_li_date">内部排名</span>
              </div>
              {this.state.ranklist.map((item,index)=>{
                return  <div className={item.sourcechannel == localStorage.getItem('userid')?"iviteusersbox_list_linew ismyrank":"iviteusersbox_list_linew"} key={"ranklist"+index}>
                <span className="iviteusersbox_list_li_name">{item.username}</span>
                <span className="iviteusersbox_list_li_tel">{item.sourcenum}</span>
                <span className="iviteusersbox_list_li_date">{index+1}</span>
              </div>
              })}
             
           </div></>:<div className='scorelayerbox_listnew'><p>快来参与，登榜领好礼！</p></div>}
           
           
        </div>
      </div>
    }
    
    let scorelayer = null
    if(this.state.showlayer){
      scorelayer=<div className="scorelayer">
          <div className="scorelayerbox">
            <div className="scorelayerbox_title">{this.state.isinteranl?"邀请新用户得好礼":"邀请新用户得积分"} <div className="scorelayerbox_close" onClick={()=>this.setState({
              showlayer:false,
            })}></div></div>
            
            {/* <div className="scorelayerbox_list_tips">2023年5月12日起邀请的客户会获取积分</div> */}
            <div className="scorelayerbox_list">
              {this.state.isinteranl?"":<> <div className="scorelayerbox_list_title">分享邀请新客户注册可获取积分，邀请新的新用户越多，可获取更多积分</div>
              <div className="scorelayerbox_list_desc">邀请1-5位新注册用户<span>+100</span></div>
              <div className="scorelayerbox_list_desc">邀请6位以上新注册用户<span>+200</span></div>
              <div className="scorelayerbox_list_line"></div>
              <div className="scorelayerbox_list_usertitle">用户邀请列表<span>(2023年5月12日起邀请的客户会获取积分)</span></div></>}
             

              <div className={this.state.isinteranl?"iviteusersbox_list_li bold margintop":"iviteusersbox_list_li bold"}>
                <span className="iviteusersbox_list_li_name">姓名</span>
                <span className="iviteusersbox_list_li_tel">手机号</span>
                <span className="iviteusersbox_list_li_date">注册时间</span>
                {this.state.isinteranl?"": <span className="iviteusersbox_list_li_nums">获取积分</span>}
              </div>
              
              {this.state.scorelist.map((item)=>{
                return  <div className={this.state.isinteranl?"iviteusersbox_list_li  margintop":"iviteusersbox_list_li "}>
                <span className="iviteusersbox_list_li_name">{item.name}<i></i></span>
                <span className="iviteusersbox_list_li_tel">{item.phone}</span>
                <span className="iviteusersbox_list_li_date">{item.createdate} {item.createtime}</span>
                {this.state.isinteranl?"": <span className="iviteusersbox_list_li_nums">+{item.score}</span>}
              </div>
              })}

            </div>
          </div>
      </div>
    }

    let scorelsyerstatehtml = null
    if(this.state.scorelsyerstate){
      scorelsyerstatehtml= <Scorelayer closescorelayer={this.closescorelayer} scorelsyerlist={this.state.scorelsyerlist}></Scorelayer> 
    }

   
    return (
      <div className='personpage'>
        {scorelsyerstatehtml}
        {scorelayer}
        {eglistlayer}
        {ranklayer}
        {this.state.showusersalepointlist?<Internalpoints changepointsdetaillayer={this.changepointsdetaillayer} closepointstatelayer = {this.closepointstatelayer}></Internalpoints>:null}
        {this.state.showusersalepointdetail?<Internalpointsdetail  closepointstatelayer = {this.closepointstatedetaillayer}></Internalpointsdetail>:null}
        <Header active='4' signstate={this.state.signstate} todayclick={this.todayclick} signscore={this.state.signscore} signnums={this.state.signnums}></Header>
        {/* <PersonImg type="addmore"></PersonImg> */}
        <PersonImg showranklayer={this.showranklayer}  insidesourcenum={this.state.insidesourcenum} ranknum={this.state.ranknum} isinteranl={this.state.isinteranl} isonlyshow={true} changeopenisspecialtips = {()=>this.changeopenisspecialtips()} openisspecialtips={this.state.openisspecialtips} changeopenstate ={()=>this.changeopenstate()} type="addmore" backusernumstate={true}  backusernum={this.state.backusernum}  onMouseEnter={this.onMouseEnter} thisnum={this.state.thisnum} onMouseLeave={this.onMouseLeave} mousestate={this.state.mousestate} isperson='1' signstate={this.state.todaysign} cslevel={this.state.cslevel} todayclick={this.todayclick} signnums={this.state.signnums} signlevel={this.state.signlevel} signlevelscore={this.state.signlevelscore} signallscore={this.state.signallscore} showlayer={this.showlayer}  score={this.state.score} scoreusernum={this.state.scoreusernum} ></PersonImg>
       
        <section className="person-list">
          <div className="wrap">
            <ul className="person-listbox">
              {hreflist}
              {hreflistnew}
              
            </ul>
          </div>
        </section>
        <LayoutClass
          key='推荐课程'
          title='推荐课程'
          list={classlist}></LayoutClass>
        <Footer></Footer>
      </div >
    )
  }
}

export default HomePerson
